import config from '@/config'
import Announcement from '@/components/announcement/index.vue'
export default {

  data() {
    return {
      address: '',
      totalSupply: 0,
      isOpen: false
    };
  },
  components: { Announcement },
  created: function () {
    this.address = config.connextErc20AddressIndex
    this.totalSupply = config.totalSupply
    this.isOpen = config.isOpen
    // this.currentAccount = window.localStorage.getItem("LOGIN_ACCOUNT_ADDRESS");
    // this.currentProvider = window.localStorage.getItem("LOGIN_PROVIDER");
    // if (this.currentAccount == "undefined") this.currentAccount = null;
    // if (window.ethereum && window.ethereum.isConnected()) {
    //   this.initMetamask();
    // }
  },
  filters: {
    formatAddress(address) {
      if (address) {
        return (
          address.substring(0, 6) +
          "..." +
          address.substring(address.length - 4)
        );
      }
    },
    formatBigData(bigData){
      let mbigData = bigData/1000000;
      if(mbigData >= 1){
        return mbigData.toFixed(2)+'M';
      }else{
        mbigData = bigData/1000;
        if(mbigData >= 1){
          return mbigData.toFixed(2)+'K';
        }
      }

      return (bigData*1).toFixed(2)
    },
    formatPercentage(data){
      return (data*100).toFixed(4) + '%';
    }
  },
  methods: {
    toRouter(name){
      const that = this
      if(!that.isOpen){
        const h = this.$createElement;
        const img = require("../assets/images/flat-color-icons_ing.png")
        that.$message({
          message: h('div', {class:'content'}, [
            h("img", { attrs: {
              src: img,
              class: 'icon'
            } }),
            h('span', null, 'Coming Soon!')
          ]),
          iconClass: ' ',
          customClass: 'info-message',
          showClose: true
        })
        return
      }
      that.$router.push({name:name})
    },
    scrollToNowPage(docId) {
      document.getElementById(docId).scrollIntoView();
    },
    open(link) {
      window.open(link,'_blank');
    }
  }
};
<template>
    <div class="comp-confirm" v-if="isShow">
        <div class="layer" @click="onHide"></div>
        <div class="content">
            <div class="confirm-header">
                <p class="cancel">
                    <img src="../../assets/images/announce/ic-close.png" class="icon" @click="onHide" />
                </p>
                <div class="title">
                    <i class="icon"></i>
                    <img src="../../assets/images/announce/ic-an.png" class="icon" @click="onHide" />
                    <span class="text">Announcement</span>
                </div>
            </div>
            <div class="confirm-body">
                The Connex DAO platform strictly abides by the laws and regulations of various countries and regions. According to the laws and regulations of various countries on encrypted assets, the project is currently unable to provide services for users in the following countries: United States of America, Mainland China, Japan, Iran, Albania,Belarus, Northern Mariana Islands, Puerto Rico, Guam, Syria, Democratic Republic of Congo, Cuba, Iraq, North Korea, Sudan,  Zimbabwe, etc. Sorry for any inconvenience caused.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // props: ["isShow"],
    data() {
        return {
            isShow: false
        }
    },

    mounted() {
        setTimeout(() => {
            this.isShow = true
        }, 500)
    },

    methods: {
        onHide() {
            this.isShow = false
            // this.$emit("update:isShow", false);
        }
    }
}
</script>

<style lang="scss" scoped>
.layer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.comp-confirm {
    position: relative;
    z-index: 999;
    .content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 360px;
        background: #ffffff;
        border-radius: 16px;
        color: #14171f;
        background-image: url(../../assets/images/announce/img-bg.png);
        background-size: 120px 120px;
        background-position: 14px 14px;
        background-repeat: no-repeat;

        .confirm-header {
            .cancel {
                padding-top: 10px;
                padding-right: 20px;
                text-align: right;
                .icon {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    cursor: pointer;
                }
            }
            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 38px;
                .icon {
                    display: inline-block;
                    width: 28px;
                    height: 29px;
                    margin-right: 10px;
                }
                .text {
                    font-size: 18px;
                    font-family: 'Gilroy-Bold';
                    font-weight: bold;
                }
            }
        }

        .confirm-body {
            margin: 20px 24px;
            font-size: 12px;
            line-height: 1.6;
            text-align: center;
        }
    }
}
@media screen and (orientation: portrait) {
    .comp-confirm .content {
        width: 92%;
    }
}
</style>
